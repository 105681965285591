import templateDetail from '@/components/template-detail/template-detail.vue';
import emailHistory from '@/components/emailHistory/emailHistory.vue';
import { mapState } from 'vuex';
import dateUtils from '@/services/dateUtilities/dateUtilities';
import graphQLUtilities from '../../services/graphQLUtilities/graphQLUtilities';
import roleServices from '@/services/roleServices/roleServices';

export default {
  components: { templateDetail, emailHistory },
  computed: {
    ...mapState('templates', ['currentTemplate']),
    ...mapState('applications', ['currentApplication']),
    ...mapState('auth', ['user'])
  },
  props: ['templateId'],
  data() {
    return {
      formInvalid: false,
      editable: false,
      loading: true,
      updating: false,
      creatorLoading: true,
      showHistory: false,
      processing: false,
      creator: {
        FirstName: '',
        LastName: ''
      },
      friendlyDate: '',
      historyToggle: false,
      updatedBody: '',
      isAdmin: false,
      deleteDialog: false,
      readOnly: true
    };
  },
  async mounted() {
    this.loading = true;
    if (this.$route.params.id !== this.currentApplication.Id) {
      await graphQLUtilities.getApplication(this.user.commonid, this.$route.params.id);
    }
    this.isAdmin = roleServices.checkRole('admin');
    this.showHistory = roleServices.checkRole('viewHistory');
    this.readOnly = roleServices.checkRole('readOnly');
    this.loading = false;
  },
  watch: {
    $route: {
      handler: async function() {
        await this.refreshTemplateList(false);
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.$store.state.templates.currentTemplate = {};
  },
  methods: {
    editableSwitch() {
      this.editable = !this.editable;
    },
    async refreshTemplateList(shouldUpdate = true) {
      this.updating = shouldUpdate;
      await this.loadTemplateData();
      this.updating = false;
    },
    async saveTemplate() {
      try {
        this.processing = true;
        this.loading = true;

        this.currentTemplate.Body = this.updatedBody ? this.updatedBody : this.currentTemplate.Body;
        await graphQLUtilities.updateTemplate(this.currentTemplate, this.user.commonid);
        this.editable = !this.editable;
        this.$router.push({ name: 'template', params: { id: this.$route.params.id, templateId: this.$route.params.templateId, versionId: this.currentTemplate.VersionId } });
        this.$snotify.success('Template Updated');
      } catch (err) {
        this.$snotify.error('Template Not Updated.');
      }
      this.processing = false;
      this.loading = false;
    },
    async loadTemplateData() {
      await graphQLUtilities.getTemplate(this.$route.params.templateId, this.$route.params.versionId);
      this.creatorLoading = true;
      this.creator = await graphQLUtilities.getUser(this.currentTemplate.CreatedBy);
      this.friendlyDate = dateUtils.formatDateTimeToString(this.currentTemplate.CreatedDateTime, true);
      this.creatorLoading = false;
    },
    updateBody(value) {
      this.updatedBody = value;
    },
    async deleteTemplate() {
      try {
        this.updating = true;
        await graphQLUtilities.deleteTemplate(this.currentTemplate.Id);
        this.$snotify.success('Template successfully deleted.');
        this.$router.push({ name: 'application', params: { id: this.$route.params.id } });
        this.updating = false;
        this.deleteDialog = false;
      } catch (e) {
        this.$snotify.error('Template was not deleted successfully.');
        this.updating = false;
        this.deleteDialog = false;
      }
    }
  }
};
