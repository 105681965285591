import tinyEditor from '../tinymce/tinymce-editor.vue';
import stageCard from '../stage-card/stage-card.vue';
import defaultEmailAddresses from '@/components/default-email/default-email.vue';
import { mapState } from 'vuex';
import graphQLUtilities from '../../services/graphQLUtilities/graphQLUtilities';
import roleServices from '../../services/roleServices/roleServices';
import dateUtilities from '../../services/dateUtilities/dateUtilities';
import { required, email, maxLength } from 'vuelidate/lib/validators';

export default {
  components: { defaultEmailAddresses, tinyEditor, stageCard },
  props: ['editable', 'updating', 'templateLoading', 'cancel'],
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('templates', ['currentTemplate']),
    ...mapState('templates', ['currentTemplateVersions']),
    ...mapState('templates', ['isLoading']),
    ...mapState('applications', ['isApplicationLoading']),
    nameErrors() {
      const errors = [];
      this.isNameInvalid = false;
      if (!this.$v.currentTemplate.Name.$dirty) return errors;
      !this.$v.currentTemplate.Name.required && errors.push('Template Name is required');
      if (errors.length) {
        this.isNameInvalid = true;
      }
      this.setValidationFlag();
      return errors;
    },
    subjectErrors() {
      const errors = [];
      this.isSubjectInvalid = false;
      if (!this.$v.currentTemplate.Subject.$dirty) return errors;
      !this.$v.currentTemplate.Subject.required && errors.push('Email subject is required');

      // This limit is based off of RFC 2822 for internet message format
      // http://www.faqs.org/rfcs/rfc2822.html
      !this.$v.currentTemplate.Subject.maxLength && errors.push('Email subject must be max of 998 characters');
      if (errors.length) {
        this.isSubjectInvalid = true;
      }
      this.setValidationFlag();
      return errors;
    },
    fromAddressErrors() {
      const errors = [];
      this.isEmailInvalid = false;
      if (!this.$v.currentTemplate.DefaultEmailAddresses.From.$dirty) return errors;
      !this.$v.currentTemplate.DefaultEmailAddresses.From.required && errors.push('From Email Address is required');
      !this.$v.currentTemplate.DefaultEmailAddresses.From.email && errors.push('You must enter a valid from email address');
      if (errors.length) {
        this.isEmailInvalid = true;
      }
      this.setValidationFlag();
      return errors;
    },
    readOnly: function() {
      if (!this.isApplicationLoading) {
        return roleServices.checkRole('readOnly');
      }
    },
    devSelected: function() {
      return this.devTemplate.VersionId === this.currentTemplate.VersionId;
    },
    testSelected: function() {
      return this.testTemplate.VersionId === this.currentTemplate.VersionId;
    },
    betaSelected: function() {
      return this.betaTemplate.VersionId === this.currentTemplate.VersionId;
    },
    prodSelected: function() {
      return this.prodTemplate.VersionId === this.currentTemplate.VersionId;
    },
    promoteButtonText() {
      if (this.prodSelected) {
        return 'Promote';
      }
      if (this.betaSelected) {
        return 'Promote to Prod';
      }
      if (this.testSelected) {
        return 'Promote to Beta';
      }
      if (this.devSelected) {
        return 'Promote to Test';
      }
      return 'Promote to Dev';
    }
  },
  data() {
    return {
      application: {},
      applicationId: '',
      name: '',
      description: '',
      fromAddress: '',
      trackingPixel: false,
      doNotSendListCheck: false,
      isResendable: false,
      editorContent: '',
      body: '',
      fieldEnvironment: ['dev', 'test', 'beta', 'prod'],
      subject: '',
      currentTemplateVersion: '',
      selectedVersion: {},
      defaultAddress: null,
      loading: false,
      expansionPanels: ['Stage Status', 'Details', 'Default Email Addresses', 'Body'],
      devTemplate: {},
      testTemplate: {},
      betaTemplate: {},
      prodTemplate: {},
      expandedPanels: [0, 1, 3],
      promoting: false,
      loadEditor: false,
      stagesLoaded: false,
      isNameInvalid: false,
      isSubjectInvalid: false,
      isEmailInvalid: false
    };
  },
  validations: {
    currentTemplate: {
      Name: { required },
      Subject: { required, maxLength: maxLength(998) },
      DefaultEmailAddresses: { From: { required, email } }
    }
  },
  watch: {
    selectedVersion: async function(val) {
      for (let i in this.currentTemplateVersions) {
        if (val === this.currentTemplateVersions[i].FormattedVersionTime) {
          this.$router.push({ name: 'template', params: { id: this.$route.params.id, templateId: this.$route.params.templateId, versionId: this.currentTemplateVersions[i].VersionId } });
        }
      }
    },
    $route: {
      handler: async function() {
        await this.refreshDetails(true);
      },
      immediate: true
    }
  },
  methods: {
    setValidationFlag() {
      if (this.isEmailInvalid || this.isSubjectInvalid || this.isNameInvalid) {
        this.$emit('validationCheck', true);
      } else if (!this.isEmailInvalid && !this.isSubjectInvalid && !this.isNameInvalid) {
        this.$emit('validationCheck', false);
      }
    },
    async fetchTemplateStages() {
      this.stagesLoaded = false;
      for (let i = 0; i < this.currentTemplateVersions.length; i++) {
        let templateVersion = this.currentTemplateVersions[i];
        for (let j = 0; j < templateVersion.Stages.length; j++) {
          let stage = templateVersion.Stages[j];
          switch (stage.Stage) {
            case 'dev':
              this.devTemplate = { ...templateVersion };
              this.devTemplate.Stage = this.devTemplate.Stages.find((x) => x.Stage === 'dev');
              this.devTemplate.Stage.User = await graphQLUtilities.getUser(this.devTemplate.Stage.UserId);
              this.devTemplate.Stage.PromotedDateTime = dateUtilities.formatDateTimeToString(this.devTemplate.Stage.PromotedDateTime, true);
              break;
            case 'test':
              this.testTemplate = { ...templateVersion };
              this.testTemplate.Stage = this.testTemplate.Stages.find((x) => x.Stage === 'test');
              this.testTemplate.Stage.User = await graphQLUtilities.getUser(this.testTemplate.Stage.UserId);
              this.testTemplate.Stage.PromotedDateTime = dateUtilities.formatDateTimeToString(this.testTemplate.Stage.PromotedDateTime, true);
              break;
            case 'beta':
              this.betaTemplate = { ...templateVersion };
              this.betaTemplate.Stage = this.betaTemplate.Stages.find((x) => x.Stage === 'beta');
              this.betaTemplate.Stage.User = await graphQLUtilities.getUser(this.betaTemplate.Stage.UserId);
              this.betaTemplate.Stage.PromotedDateTime = dateUtilities.formatDateTimeToString(this.betaTemplate.Stage.PromotedDateTime, true);
              break;
            case 'prod':
              this.prodTemplate = { ...templateVersion };
              this.prodTemplate.Stage = this.prodTemplate.Stages.find((x) => x.Stage === 'prod');
              this.prodTemplate.Stage.User = await graphQLUtilities.getUser(this.prodTemplate.Stage.UserId);
              this.prodTemplate.Stage.PromotedDateTime = dateUtilities.formatDateTimeToString(this.prodTemplate.Stage.PromotedDateTime, true);
              break;
          }
        }
      }
      if (Object.keys(this.devTemplate).length < 1) {
        this.devTemplate = { FormattedDateTime: 'No Template promoted to dev', Stage: { UserId: '', Stage: 'dev', PromotedDateTime: '' } };
      }
      if (Object.keys(this.testTemplate).length < 1) {
        this.testTemplate = { FormattedDateTime: 'No Template promoted to test', Stage: { UserId: '', Stage: 'test', PromotedDateTime: '' } };
      }
      if (Object.keys(this.betaTemplate).length < 1) {
        this.betaTemplate = { FormattedDateTime: 'No Template promoted to beta', Stage: { UserId: '', Stage: 'beta', PromotedDateTime: '' } };
      }
      if (Object.keys(this.prodTemplate).length < 1) {
        this.prodTemplate = { FormattedDateTime: 'No Template promoted to prod', Stage: { UserId: '', Stage: 'prod', PromotedDateTime: '' } };
      }
      this.stagesLoaded = true;
    },

    togglePanel(panelArrayPosition) {
      if (this.expandedPanels.includes(panelArrayPosition)) {
        this.expandedPanels.splice(this.expandedPanels.indexOf(panelArrayPosition), 1);
      } else {
        this.expandedPanels.push(panelArrayPosition);
      }
    },
    assignDefaultEmailAddressed(newAddresses) {
      this.currentTemplate.DefaultEmailAddresses = newAddresses;
    },
    async selectStageCard(template) {
      if (template.Id) {
        if (template.VersionId !== this.currentTemplate.VersionId) {
          this.$router.push({ name: 'template', params: { id: this.$route.params.id, templateId: this.$route.params.templateId, versionId: template.VersionId } });
        } else {
          this.$snotify.info('Template is currently selected.');
        }
      } else {
        this.$snotify.error('No Template Promoted for that Stage');
      }
    },

    async promoteCurrentVersion() {
      try {
        this.promoting = true;
        const response = await graphQLUtilities.promoteTemplate(this.currentTemplate.Id, this.currentTemplate.VersionId, this.user.commonid, this.user.subteam);
        if (response.StatusCode === 200) {
          this.$emit('refreshTemplateList');
          this.$snotify.success(response.Message);
          await this.refreshDetails();
          this.promoting = false;
        } else {
          this.$snotify.error(response.Message);
          this.promoting = false;
        }
      } catch (err) {
        this.$snotify.error('A server error occured while promoting the template.');
        this.promoting = false;
      }
    },
    async refreshDetails(showLoading = false) {
      this.loading = showLoading;
      await graphQLUtilities.getTemplateVersions(this.$route.params.templateId);
      this.selectVersion(this.$route.params.versionId);
      await this.fetchTemplateStages();
      this.loading = false;
    },
    selectVersion(versionId) {
      this.selectedVersion = null;
      for (let i = 0; i < this.currentTemplateVersions.length; i++) {
        if (this.currentTemplateVersions[i].VersionId === versionId) {
          this.selectedVersion = this.currentTemplateVersions[i];
        }
      }
      if (!this.selectedVersion) {
        this.selectedVersion = this.currentTemplateVersions[0];
      }
    },
    bodyRecieved(value) {
      this.$emit('updateBody', value);
    }
  },
  async mounted() {
    this.selectedVersion = null;
  }
};
