import { email } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

export default {
  props: ['existing', 'editable'],
  data() {
    return {
      defaultAddress: '',
      defaultEmailAddresses: {
        To: [],
        Cc: [],
        Bcc: []
      }
    };
  },
  validations: {
    defaultAddress: { email }
  },
  mounted() {
    if (this.existing === true) {
      this.defaultEmailAddresses = this.currentTemplate.DefaultEmailAddresses;
    }
  },
  computed: {
    defaultAddressErrors() {
      const errors = [];
      if (!this.$v.defaultAddress.$dirty) return errors;
      !this.$v.defaultAddress.email &&
                errors.push('You must enter a valid from email address');
      return errors;
    },
    ...mapState('templates', ['currentTemplate'])
  },
  methods: {
    removeEmailAddress(itemToRemove, addressType) {
      if (itemToRemove !== undefined) {
        switch (addressType) {
          case 'To':
            this.defaultEmailAddresses.To.splice(itemToRemove, 1);
            break;
          case 'Cc':
            this.defaultEmailAddresses.Cc.splice(itemToRemove, 1);
            break;
          case 'Bcc':
            this.defaultEmailAddresses.Bcc.splice(itemToRemove, 1);
            break;
        }
      }
    },
    addEmailAddress(addressType) {
      if (this.defaultAddress === null || this.defaultAddress === '') {
        return;
      }

      if (this.$v.defaultAddress.$invalid) {
        return;
      }

      switch (addressType) {
        case 'To':
          this.defaultEmailAddresses.To.push(this.defaultAddress);
          this.defaultAddress = '';
          break;
        case 'Cc':
          this.defaultEmailAddresses.Cc.push(this.defaultAddress);
          this.defaultAddress = '';
          break;
        case 'Bcc':
          this.defaultEmailAddresses.Bcc.push(this.defaultAddress);
          this.defaultAddress = '';
          break;
      }
      this.$emit('newAddresses', this.defaultEmailAddresses);
    }
  }
};
